jQuery(document).ready(function($) {
	/* Mobile Menu */
	mobileNavigationMenuSelector = ".mobile-navigation-menu"
	menu = new Mmenu(mobileNavigationMenuSelector, {
		extensions: [
			"position-right"
		],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true,
		}
	});

	api = menu.API;
	$(".mobile-menu-button").click(function() {
		if ($(mobileNavigationMenuSelector).hasClass("mm-menu_opened")) {
			api.close();
		}
		else {
			api.open();
		}
	});

	/* FitVids */
	if ($("body").innerWidth() <= 767) {
		$(".inside-row-nested").fitVids({
			ignore: "nofit"
		});
		$(".cbcontent").fitVids({
			ignore: "nofit"
		});
	}

	/* Search */
	$(".search-button").click(function() {
		$(".search-bar-wrap").slideDown("slow");
	});

	$(".search-input-exit").click(function() {
		$(".search-bar-wrap").slideUp("slow");
	});

	/* Accessible menu */
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".hasChildren > .nav-link").click(function(event) {
			if (!$(this).parents(".hasChildren").hasClass("open")) {
				$(".nav-link-wrapper").removeClass("open");
				event.preventDefault();
				$(this).parent(".hasChildren").addClass("open");
			}
		});
	}
	else {
		$(".nav-link-wrapper").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}
	$(".accesible-navigation-menu").accessibleMenu();

	/* Sticky Header */
	addStickyHeader();
	$(window).scroll(function() {
		addStickyHeader();
	});

	/* eslint-disable */
	/* Webflow: Interactions: Init */
	Webflow.require('ix').init([{
			"slug": "nav-hover-one",
			"name": "nav hover one",
			"value": {
				"style": {},
				"triggers": [{
					"type": "hover",
					"selector": ".nav-underline",
					"stepsA": [{
						"opacity": 1,
						"width": "144px",
						"height": "3px",
						"transition": "width 300ms ease 0, height 300ms ease 0, opacity 300ms ease 0"
					}],
					"stepsB": [{
						"opacity": 0,
						"width": "0px",
						"transition": "width 200 ease 0, opacity 200 ease 0"
					}]
				}]
			}
		},
		{
			"slug": "nav-hover-two",
			"name": "nav hover two",
			"value": {
				"style": {},
				"triggers": [{
					"type": "hover",
					"selector": ".nav-underline-two",
					"stepsA": [{
						"opacity": 1,
						"width": "124px",
						"height": "3px",
						"transition": "width 300ms ease 0, height 300ms ease 0, opacity 300ms ease 0"
					}],
					"stepsB": [{
						"opacity": 0,
						"width": "0px",
						"transition": "width 200 ease 0, opacity 200 ease 0"
					}]
				}]
			}
		},
		{
			"slug": "nav-hover-three",
			"name": "nav hover three",
			"value": {
				"style": {},
				"triggers": [{
					"type": "hover",
					"selector": ".nav-underline-three",
					"stepsA": [{
						"opacity": 1,
						"width": "130px",
						"height": "3px",
						"transition": "width 300ms ease 0, height 300ms ease 0, opacity 300ms ease 0"
					}],
					"stepsB": [{
						"opacity": 0,
						"width": "0px",
						"transition": "width 200 ease 0, opacity 200 ease 0"
					}]
				}]
			}
		},
		{
			"slug": "nav-hover-four",
			"name": "nav hover four",
			"value": {
				"style": {},
				"triggers": [{
					"type": "hover",
					"selector": ".nav-underline-four",
					"stepsA": [{
						"opacity": 1,
						"width": "60px",
						"height": "3px",
						"transition": "width 300ms ease 0, height 300ms ease 0, opacity 300ms ease 0"
					}],
					"stepsB": [{
						"opacity": 0,
						"width": "0px",
						"transition": "width 200 ease 0, opacity 200 ease 0"
					}]
				}]
			}
		},
		{
			"slug": "nav-hover-five",
			"name": "nav hover five",
			"value": {
				"style": {},
				"triggers": [{
					"type": "hover",
					"selector": ".nav-underline-five",
					"stepsA": [{
						"opacity": 1,
						"width": "150px",
						"height": "3px",
						"transition": "width 300ms ease 0, height 300ms ease 0, opacity 300ms ease 0"
					}],
					"stepsB": [{
						"opacity": 0,
						"width": "0px",
						"transition": "width 200 ease 0, opacity 200 ease 0"
					}]
				}]
			}
		},
		{
			"slug": "nav-hover-six",
			"name": "nav hover six",
			"value": {
				"style": {},
				"triggers": [{
					"type": "hover",
					"selector": ".nav-underline-six",
					"stepsA": [{
						"opacity": 1,
						"width": "143px",
						"height": "3px",
						"transition": "width 300ms ease 0, height 300ms ease 0, opacity 300ms ease 0"
					}],
					"stepsB": [{
						"opacity": 0,
						"width": "0px",
						"transition": "width 200 ease 0, opacity 200 ease 0"
					}]
				}]
			}
		},
		{
			"slug": "scroll-arrow-animation",
			"name": "scroll arrow animation",
			"value": {
				"style": {},
				"triggers": [{
					"type": "load",
					"loopA": true,
					"stepsA": [{
						"opacity": 1,
						"transition": "transform 1000ms ease-out-quad 0, opacity 1000ms ease-out-quad 0",
						"x": "0px",
						"y": "15px",
						"z": "0px"
					}, {
						"opacity": 0,
						"transition": "transform 1000ms ease-out-quad 0, opacity 1000ms ease-out-quad 0",
						"x": "0px",
						"y": "30px",
						"z": "0px"
					}, {
						"x": "0px",
						"y": "-15px",
						"z": "0px"
					}],
					"stepsB": []
				}, {
					"type": "scroll",
					"stepsA": [{
						"opacity": 0,
						"transition": "opacity 450ms ease 0"
					}],
					"stepsB": []
				}]
			}
		},
		{
			"slug": "scrolling-login-drop",
			"name": "scrolling login drop",
			"value": {
				"style": {},
				"triggers": [{
					"type": "scroll",
					"selector": ".scrolling-login-header",
					"offsetTop": "10%",
					"offsetBot": "100%",
					"preserve3d": true,
					"stepsA": [{
						"transition": "transform 400ms ease 0",
						"x": "0px",
						"y": "45px",
						"z": "0px"
					}],
					"stepsB": [{
						"transition": "transform 200 ease 0",
						"x": "0px",
						"y": "-45px",
						"z": "0px"
					}]
				}]
			}
		},
		{
			"slug": "hover-mega-menu",
			"name": "hover mega menu",
			"value": {
				"style": {},
				"triggers": [{
					"type": "hover",
					"selector": ".dropdown-mega-menu",
					"stepsA": [{
						"display": "flex"
					}, {
						"opacity": 1,
						"transition": "opacity 400ms ease-out-quad 0"
					}],
					"stepsB": []
				}]
			}
		},
		{
			"slug": "hover-menu-hover-out",
			"name": "hover menu hover out",
			"value": {
				"style": {},
				"triggers": [{
					"type": "hover",
					"stepsA": [],
					"stepsB": [{
						"opacity": 0,
						"transition": "opacity 400ms ease-out-quad 0"
					}, {
						"display": "none"
					}]
				}]
			}
		}
	]);

	/* Webflow: Interactions 2.0: Init */
	Webflow.require('ix2').init({
		"events": {
			"e-10": {
				"id": "e-10",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInBottom",
						"autoStopEventId": "e-12"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "614b9b789937c3ff3ca2756b|84b04995-8701-af25-ee5c-371f6445003a",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "614b9b789937c3ff3ca2756b|84b04995-8701-af25-ee5c-371f6445003a",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": 400,
					"direction": "BOTTOM",
					"effectIn": true
				},
				"createdOn": 1613569298041
			},
			"e-20": {
				"id": "e-20",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInBottom",
						"autoStopEventId": "e-15"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "614b9b789937c3ff3ca2756b|84b04995-8701-af25-ee5c-371f6445002b",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "614b9b789937c3ff3ca2756b|84b04995-8701-af25-ee5c-371f6445002b",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": 0,
					"direction": "BOTTOM",
					"effectIn": true
				},
				"createdOn": 1613569263067
			},
			"e-21": {
				"id": "e-21",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInBottom",
						"autoStopEventId": "e-14"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "614b9b789937c3ff3ca2756b|84b04995-8701-af25-ee5c-371f64450032",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "614b9b789937c3ff3ca2756b|84b04995-8701-af25-ee5c-371f64450032",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": 250,
					"direction": "BOTTOM",
					"effectIn": true
				},
				"createdOn": 1613569275947
			},
			"e-25": {
				"id": "e-25",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInBottom",
						"autoStopEventId": "e-26"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "614b9b789937c3ff3ca2756b|b080b410-ce20-aa3c-76d7-71a36540eeb3",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "614b9b789937c3ff3ca2756b|b080b410-ce20-aa3c-76d7-71a36540eeb3",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": 600,
					"direction": "BOTTOM",
					"effectIn": true
				},
				"createdOn": 1631541237020
			},
			"e-27": {
				"id": "e-27",
				"name": "",
				"animationType": "custom",
				"eventTypeId": "MOUSE_OVER",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-28"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"selector": ".icon-link-wrapper",
					"originalId": "614b9b789937c3ff3ca2756b|84b04995-8701-af25-ee5c-371f6445002b",
					"appliesTo": "CLASS"
				},
				"targets": [{
					"selector": ".icon-link-wrapper",
					"originalId": "614b9b789937c3ff3ca2756b|84b04995-8701-af25-ee5c-371f6445002b",
					"appliesTo": "CLASS"
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1631542751040
			},
			"e-28": {
				"id": "e-28",
				"name": "",
				"animationType": "custom",
				"eventTypeId": "MOUSE_OUT",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-2",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-27"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"selector": ".icon-link-wrapper",
					"originalId": "614b9b789937c3ff3ca2756b|84b04995-8701-af25-ee5c-371f6445002b",
					"appliesTo": "CLASS"
				},
				"targets": [{
					"selector": ".icon-link-wrapper",
					"originalId": "614b9b789937c3ff3ca2756b|84b04995-8701-af25-ee5c-371f6445002b",
					"appliesTo": "CLASS"
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1631542751041
			},
			"e-33": {
				"id": "e-33",
				"name": "",
				"animationType": "custom",
				"eventTypeId": "MOUSE_OVER",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-5",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-44"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "614b9b789937c3ff3ca2756b|1bb754c8-d8a9-f07c-c5fc-e4744f9b4db9",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"selector": ".resources-list-cb-link",
					"originalId": "614b9b789937c3ff3ca2756b|1bb754c8-d8a9-f07c-c5fc-e4744f9b4db9",
					"appliesTo": "CLASS"
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1631569278607
			},
			"e-34": {
				"id": "e-34",
				"name": "",
				"animationType": "custom",
				"eventTypeId": "MOUSE_OUT",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-6",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-43"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "614b9b789937c3ff3ca2756b|1bb754c8-d8a9-f07c-c5fc-e4744f9b4db9",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"selector": ".resources-list-cb-link",
					"originalId": "614b9b789937c3ff3ca2756b|1bb754c8-d8a9-f07c-c5fc-e4744f9b4db9",
					"appliesTo": "CLASS"
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1631569278608
			},
			"e-39": {
				"id": "e-39",
				"name": "",
				"animationType": "custom",
				"eventTypeId": "MOUSE_CLICK",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-8",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-40"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "7310444d-f829-2e08-cac3-20866e83d2dc",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "7310444d-f829-2e08-cac3-20866e83d2dc",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1631642856321
			},
			"e-41": {
				"id": "e-41",
				"name": "",
				"animationType": "custom",
				"eventTypeId": "MOUSE_CLICK",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-9",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-42"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "7310444d-f829-2e08-cac3-20866e83d2e2",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "7310444d-f829-2e08-cac3-20866e83d2e2",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1631643268137
			},
			"e-43": {
				"id": "e-43",
				"name": "",
				"animationType": "custom",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-12",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-44"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "614b9b789937c3ff3ca2756b|67646289-97e6-4525-2c91-3d0851f61e2a",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "614b9b789937c3ff3ca2756b|67646289-97e6-4525-2c91-3d0851f61e2a",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1599771774359
			},
			"e-44": {
				"id": "e-44",
				"name": "",
				"animationType": "custom",
				"eventTypeId": "SCROLL_OUT_OF_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-11",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-43"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "614b9b789937c3ff3ca2756b|67646289-97e6-4525-2c91-3d0851f61e2a",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "614b9b789937c3ff3ca2756b|67646289-97e6-4525-2c91-3d0851f61e2a",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1599771774360
			},
			"e-45": {
				"id": "e-45",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "GROW_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "growIn",
						"autoStopEventId": "e-46"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "614b9b789937c3ff3ca2756b|7578c416-6535-8d11-61c4-f4b58f553dbf",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "614b9b789937c3ff3ca2756b|7578c416-6535-8d11-61c4-f4b58f553dbf",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 30,
					"scrollOffsetUnit": "%",
					"delay": 100,
					"direction": null,
					"effectIn": true
				},
				"createdOn": 1631733814036
			},
			"e-47": {
				"id": "e-47",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInLeft",
						"autoStopEventId": "e-48"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "614b9b789937c3ff3ca2756b|a62587bc-6086-dcce-c68d-ad8726e33d8f",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "614b9b789937c3ff3ca2756b|a62587bc-6086-dcce-c68d-ad8726e33d8f",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 20,
					"scrollOffsetUnit": "%",
					"delay": 250,
					"direction": "LEFT",
					"effectIn": true
				},
				"createdOn": 1631747674781
			},
			"e-85": {
				"id": "e-85",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInRight",
						"autoStopEventId": "e-86"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "614b9b789937c3ff3ca2756b|9036e334-dca5-fc8e-28dd-10ce5159a768",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "614b9b789937c3ff3ca2756b|9036e334-dca5-fc8e-28dd-10ce5159a768",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 20,
					"scrollOffsetUnit": "%",
					"delay": 250,
					"direction": "RIGHT",
					"effectIn": true
				},
				"createdOn": 1631748551147
			},
			"e-87": {
				"id": "e-87",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInBottom",
						"autoStopEventId": "e-88"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "614b9b789937c3ff3ca2756b|ca4dcbf7-6d8c-45f2-50a6-122b45c3e54f",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "614b9b789937c3ff3ca2756b|ca4dcbf7-6d8c-45f2-50a6-122b45c3e54f",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 20,
					"scrollOffsetUnit": "%",
					"delay": 0,
					"direction": "BOTTOM",
					"effectIn": true
				},
				"createdOn": 1631748703023
			},
			"e-89": {
				"id": "e-89",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInBottom",
						"autoStopEventId": "e-90"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "614b9b789937c3ff3ca2756b|7292a74e-8352-5383-8f70-fc8be126fc95",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "614b9b789937c3ff3ca2756b|7292a74e-8352-5383-8f70-fc8be126fc95",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 20,
					"scrollOffsetUnit": "%",
					"delay": 200,
					"direction": "BOTTOM",
					"effectIn": true
				},
				"createdOn": 1631748722305
			},
			"e-91": {
				"id": "e-91",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInBottom",
						"autoStopEventId": "e-92"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "614b9b789937c3ff3ca2756b|d853a01c-fdcf-a6ec-dd62-dc9e3168c438",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "614b9b789937c3ff3ca2756b|d853a01c-fdcf-a6ec-dd62-dc9e3168c438",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 20,
					"scrollOffsetUnit": "%",
					"delay": 300,
					"direction": "BOTTOM",
					"effectIn": true
				},
				"createdOn": 1631748738773
			},
			"e-93": {
				"id": "e-93",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInRight",
						"autoStopEventId": "e-94"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "614b9b789937c3ff3ca2756b|69f5bf56-34e6-6677-f2c9-fcccf518c928",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "614b9b789937c3ff3ca2756b|69f5bf56-34e6-6677-f2c9-fcccf518c928",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 20,
					"scrollOffsetUnit": "%",
					"delay": 400,
					"direction": "RIGHT",
					"effectIn": true
				},
				"createdOn": 1631748760581
			},
			"e-95": {
				"id": "e-95",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInRight",
						"autoStopEventId": "e-96"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "614b9b789937c3ff3ca2756b|71064e67-142b-78d7-c466-e7362caacb5e",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "614b9b789937c3ff3ca2756b|71064e67-142b-78d7-c466-e7362caacb5e",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 20,
					"scrollOffsetUnit": "%",
					"delay": 550,
					"direction": "RIGHT",
					"effectIn": true
				},
				"createdOn": 1631748799041
			},
			"e-97": {
				"id": "e-97",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInRight",
						"autoStopEventId": "e-98"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "614b9b789937c3ff3ca2756b|2b8f6825-a893-53f2-e015-869bb19fa67b",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "614b9b789937c3ff3ca2756b|2b8f6825-a893-53f2-e015-869bb19fa67b",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 20,
					"scrollOffsetUnit": "%",
					"delay": 650,
					"direction": "RIGHT",
					"effectIn": true
				},
				"createdOn": 1631748812381
			},
			"e-99": {
				"id": "e-99",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInRight",
						"autoStopEventId": "e-100"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "614b9b789937c3ff3ca2756b|52d77029-49d9-b431-3915-5d839125348a",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "614b9b789937c3ff3ca2756b|52d77029-49d9-b431-3915-5d839125348a",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 15,
					"scrollOffsetUnit": "%",
					"delay": 750,
					"direction": "RIGHT",
					"effectIn": true
				},
				"createdOn": 1631748941909
			},
			"e-101": {
				"id": "e-101",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInRight",
						"autoStopEventId": "e-102"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "614b9b789937c3ff3ca2756b|52d77029-49d9-b431-3915-5d8391253492",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "614b9b789937c3ff3ca2756b|52d77029-49d9-b431-3915-5d8391253492",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 15,
					"scrollOffsetUnit": "%",
					"delay": 850,
					"direction": "RIGHT",
					"effectIn": true
				},
				"createdOn": 1631748966565
			},
			"e-103": {
				"id": "e-103",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInRight",
						"autoStopEventId": "e-104"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "614b9b789937c3ff3ca2756b|52d77029-49d9-b431-3915-5d839125349a",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "614b9b789937c3ff3ca2756b|52d77029-49d9-b431-3915-5d839125349a",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 15,
					"scrollOffsetUnit": "%",
					"delay": 950,
					"direction": "RIGHT",
					"effectIn": true
				},
				"createdOn": 1631748980344
			},
			"e-117": {
				"id": "e-117",
				"name": "",
				"animationType": "custom",
				"eventTypeId": "MOUSE_OVER",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-16",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-118"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"selector": ".resources-list-item",
					"originalId": "614b9b789937c3ff3ca2756b|52d77029-49d9-b431-3915-5d839125348a",
					"appliesTo": "CLASS"
				},
				"targets": [{
					"selector": ".resources-list-item",
					"originalId": "614b9b789937c3ff3ca2756b|52d77029-49d9-b431-3915-5d839125348a",
					"appliesTo": "CLASS"
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1631821000912
			},
			"e-118": {
				"id": "e-118",
				"name": "",
				"animationType": "custom",
				"eventTypeId": "MOUSE_OUT",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-17",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-117"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"selector": ".resources-list-item",
					"originalId": "614b9b789937c3ff3ca2756b|52d77029-49d9-b431-3915-5d839125348a",
					"appliesTo": "CLASS"
				},
				"targets": [{
					"selector": ".resources-list-item",
					"originalId": "614b9b789937c3ff3ca2756b|52d77029-49d9-b431-3915-5d839125348a",
					"appliesTo": "CLASS"
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1631821000916
			},
			"e-119": {
				"id": "e-119",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInBottom",
						"autoStopEventId": "e-120"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "614b9b789937c3ff3ca2756b|74c7271c-729b-863c-98a5-e77e393aa2b9",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "614b9b789937c3ff3ca2756b|74c7271c-729b-863c-98a5-e77e393aa2b9",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 20,
					"scrollOffsetUnit": "%",
					"delay": 0,
					"direction": "BOTTOM",
					"effectIn": true
				},
				"createdOn": 1631824709358
			},
			"e-121": {
				"id": "e-121",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInBottom",
						"autoStopEventId": "e-122"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "614b9b789937c3ff3ca2756b|74c7271c-729b-863c-98a5-e77e393aa2bb",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "614b9b789937c3ff3ca2756b|74c7271c-729b-863c-98a5-e77e393aa2bb",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 20,
					"scrollOffsetUnit": "%",
					"delay": 200,
					"direction": "BOTTOM",
					"effectIn": true
				},
				"createdOn": 1631824709358
			},
			"e-123": {
				"id": "e-123",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInBottom",
						"autoStopEventId": "e-124"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "614b9b789937c3ff3ca2756b|74c7271c-729b-863c-98a5-e77e393aa2bc",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "614b9b789937c3ff3ca2756b|74c7271c-729b-863c-98a5-e77e393aa2bc",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 20,
					"scrollOffsetUnit": "%",
					"delay": 300,
					"direction": "BOTTOM",
					"effectIn": true
				},
				"createdOn": 1631824709358
			},
			"e-129": {
				"id": "e-129",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInRight",
						"autoStopEventId": "e-130"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "614b9b789937c3ff3ca2756b|2167c44f-098f-5847-73fd-e7f38090258b",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "614b9b789937c3ff3ca2756b|2167c44f-098f-5847-73fd-e7f38090258b",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 20,
					"scrollOffsetUnit": "%",
					"delay": 600,
					"direction": "RIGHT",
					"effectIn": true
				},
				"createdOn": 1632145902767
			},
			"e-131": {
				"id": "e-131",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInLeft",
						"autoStopEventId": "e-132"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "614b9b789937c3ff3ca2756b|9036e334-dca5-fc8e-28dd-10ce5159a76a",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "614b9b789937c3ff3ca2756b|9036e334-dca5-fc8e-28dd-10ce5159a76a",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 20,
					"scrollOffsetUnit": "%",
					"delay": 600,
					"direction": "LEFT",
					"effectIn": true
				},
				"createdOn": 1632146054091
			},
			"e-133": {
				"id": "e-133",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInBottom",
						"autoStopEventId": "e-134"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "614b9b789937c3ff3ca2756b|bcc0d41b-28ee-9cec-1507-e8db31900352",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "614b9b789937c3ff3ca2756b|bcc0d41b-28ee-9cec-1507-e8db31900352",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 20,
					"scrollOffsetUnit": "%",
					"delay": 400,
					"direction": "BOTTOM",
					"effectIn": true
				},
				"createdOn": 1632146820269
			},
			"e-135": {
				"id": "e-135",
				"name": "",
				"animationType": "custom",
				"eventTypeId": "MOUSE_OVER",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-14",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-136"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "7310444d-f829-2e08-cac3-20866e83d256",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "7310444d-f829-2e08-cac3-20866e83d256",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1632430045134
			},
			"e-136": {
				"id": "e-136",
				"name": "",
				"animationType": "custom",
				"eventTypeId": "MOUSE_OUT",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-15",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-135"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "7310444d-f829-2e08-cac3-20866e83d256",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "7310444d-f829-2e08-cac3-20866e83d256",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1632430045137
			},
			"e-137": {
				"id": "e-137",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-12",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-138"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "616090edfc66203c510d61aa|62ac7a07-a546-4728-b0f7-58b228e42c12",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "616090edfc66203c510d61aa|62ac7a07-a546-4728-b0f7-58b228e42c12",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1633718609580
			},
			"e-138": {
				"id": "e-138",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_OUT_OF_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-11",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-137"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "616090edfc66203c510d61aa|62ac7a07-a546-4728-b0f7-58b228e42c12",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "616090edfc66203c510d61aa|62ac7a07-a546-4728-b0f7-58b228e42c12",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1633718609580
			},
			"e-139": {
				"id": "e-139",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-12",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-140"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "6164897b283c6f07569f668f|62ac7a07-a546-4728-b0f7-58b228e42c12",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "6164897b283c6f07569f668f|62ac7a07-a546-4728-b0f7-58b228e42c12",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1633978747492
			},
			"e-140": {
				"id": "e-140",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_OUT_OF_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-11",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-139"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "6164897b283c6f07569f668f|62ac7a07-a546-4728-b0f7-58b228e42c12",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "6164897b283c6f07569f668f|62ac7a07-a546-4728-b0f7-58b228e42c12",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1633978747492
			},
			"e-141": {
				"id": "e-141",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-12",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-142"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "6164932e4f9b016f62b14b3b|62ac7a07-a546-4728-b0f7-58b228e42c12",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "6164932e4f9b016f62b14b3b|62ac7a07-a546-4728-b0f7-58b228e42c12",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1633981231160
			},
			"e-142": {
				"id": "e-142",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_OUT_OF_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-11",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-141"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "6164932e4f9b016f62b14b3b|62ac7a07-a546-4728-b0f7-58b228e42c12",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "6164932e4f9b016f62b14b3b|62ac7a07-a546-4728-b0f7-58b228e42c12",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1633981231160
			},
			"e-143": {
				"id": "e-143",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-12",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-144"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "6164965e46e0e98498f8462b|62ac7a07-a546-4728-b0f7-58b228e42c12",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "6164965e46e0e98498f8462b|62ac7a07-a546-4728-b0f7-58b228e42c12",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1633982047135
			},
			"e-144": {
				"id": "e-144",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_OUT_OF_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-11",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-143"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "6164965e46e0e98498f8462b|62ac7a07-a546-4728-b0f7-58b228e42c12",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "6164965e46e0e98498f8462b|62ac7a07-a546-4728-b0f7-58b228e42c12",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1633982047135
			},
			"e-145": {
				"id": "e-145",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-12",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-146"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "616496b6bce7219a2a1b3ef5|62ac7a07-a546-4728-b0f7-58b228e42c12",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "616496b6bce7219a2a1b3ef5|62ac7a07-a546-4728-b0f7-58b228e42c12",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1633982134619
			},
			"e-146": {
				"id": "e-146",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_OUT_OF_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-11",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-145"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "616496b6bce7219a2a1b3ef5|62ac7a07-a546-4728-b0f7-58b228e42c12",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "616496b6bce7219a2a1b3ef5|62ac7a07-a546-4728-b0f7-58b228e42c12",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1633982134619
			},
			"e-147": {
				"id": "e-147",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-12",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-148"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "61649b306fcfd12d0a1a617a|62ac7a07-a546-4728-b0f7-58b228e42c12",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "61649b306fcfd12d0a1a617a|62ac7a07-a546-4728-b0f7-58b228e42c12",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1633983280746
			},
			"e-148": {
				"id": "e-148",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_OUT_OF_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-11",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-147"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "61649b306fcfd12d0a1a617a|62ac7a07-a546-4728-b0f7-58b228e42c12",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "61649b306fcfd12d0a1a617a|62ac7a07-a546-4728-b0f7-58b228e42c12",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1633983280746
			},
			"e-149": {
				"id": "e-149",
				"name": "",
				"animationType": "custom",
				"eventTypeId": "MOUSE_OVER",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-18",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-150"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"selector": ".cb-wrapper",
					"originalId": "6164897b283c6f07569f668f|7f6e5f23-a87a-b0db-0374-38d869e0ec99",
					"appliesTo": "CLASS"
				},
				"targets": [{
					"selector": ".cb-wrapper",
					"originalId": "6164897b283c6f07569f668f|7f6e5f23-a87a-b0db-0374-38d869e0ec99",
					"appliesTo": "CLASS"
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1634562488572
			},
			"e-150": {
				"id": "e-150",
				"name": "",
				"animationType": "custom",
				"eventTypeId": "MOUSE_OUT",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-19",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-149"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"selector": ".cb-wrapper",
					"originalId": "6164897b283c6f07569f668f|7f6e5f23-a87a-b0db-0374-38d869e0ec99",
					"appliesTo": "CLASS"
				},
				"targets": [{
					"selector": ".cb-wrapper",
					"originalId": "6164897b283c6f07569f668f|7f6e5f23-a87a-b0db-0374-38d869e0ec99",
					"appliesTo": "CLASS"
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1634562488575
			}
		},
		"actionLists": {
			"a": {
				"id": "a",
				"title": "icon block hover",
				"actionItemGroups": [{
					"actionItems": [{
						"id": "a-n-3",
						"actionTypeId": "STYLE_TEXT_COLOR",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 500,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".h3-headings",
								"selectorGuids": ["7341eee8-3d3f-791a-b299-bc2c5939eb7b"]
							},
							"globalSwatchId": "4f0d3bd6",
							"rValue": 28,
							"bValue": 102,
							"gValue": 61,
							"aValue": 1
						}
					}, {
						"id": "a-n-5",
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 500,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".icon-wrap",
								"selectorGuids": ["7341eee8-3d3f-791a-b299-bc2c5939eb81"]
							},
							"yValue": 0,
							"xUnit": "PX",
							"yUnit": "px",
							"zUnit": "PX"
						}
					}]
				}, {
					"actionItems": [{
						"id": "a-n-4",
						"actionTypeId": "STYLE_TEXT_COLOR",
						"config": {
							"delay": 0,
							"easing": "easeIn",
							"duration": 200,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".h3-headings",
								"selectorGuids": ["7341eee8-3d3f-791a-b299-bc2c5939eb7b"]
							},
							"globalSwatchId": "d7f3d0d9",
							"rValue": 170,
							"bValue": 71,
							"gValue": 30,
							"aValue": 1
						}
					}, {
						"id": "a-n-6",
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"easing": "inOutQuad",
							"duration": 300,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".icon-wrap",
								"selectorGuids": ["7341eee8-3d3f-791a-b299-bc2c5939eb81"]
							},
							"yValue": -8,
							"xUnit": "PX",
							"yUnit": "px",
							"zUnit": "PX"
						}
					}]
				}],
				"useFirstGroupAsInitialState": true,
				"createdOn": 1631542766316
			},
			"a-2": {
				"id": "a-2",
				"title": "icon block hover out",
				"actionItemGroups": [{
					"actionItems": [{
						"id": "a-2-n",
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"easing": "easeOut",
							"duration": 200,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".icon-wrap",
								"selectorGuids": ["7341eee8-3d3f-791a-b299-bc2c5939eb81"]
							},
							"yValue": 0,
							"xUnit": "PX",
							"yUnit": "px",
							"zUnit": "PX"
						}
					}, {
						"id": "a-2-n-2",
						"actionTypeId": "STYLE_TEXT_COLOR",
						"config": {
							"delay": 0,
							"easing": "easeOut",
							"duration": 100,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".h3-headings",
								"selectorGuids": ["7341eee8-3d3f-791a-b299-bc2c5939eb7b"]
							},
							"globalSwatchId": "4f0d3bd6",
							"rValue": 28,
							"bValue": 102,
							"gValue": 61,
							"aValue": 1
						}
					}]
				}],
				"useFirstGroupAsInitialState": false,
				"createdOn": 1631542766316
			},
			"a-5": {
				"id": "a-5",
				"title": "resource list item hover",
				"actionItemGroups": [{
					"actionItems": [{
						"id": "a-5-n",
						"actionTypeId": "STYLE_TEXT_COLOR",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 500,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".resources-list-link",
								"selectorGuids": ["2c83ce5d-ee26-deca-6ca6-e24f7d5ea30b"]
							},
							"globalSwatchId": "4f0d3bd6",
							"rValue": 28,
							"bValue": 102,
							"gValue": 61,
							"aValue": 1
						}
					}, {
						"id": "a-5-n-3",
						"actionTypeId": "STYLE_BACKGROUND_COLOR",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 500,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".arrow-link-block.resources",
								"selectorGuids": ["6707f1ef-0211-9eca-af1f-a25aee77ef3a", "2222b338-734e-8774-8abe-78f3087c30a0"]
							},
							"globalSwatchId": "4f0d3bd6",
							"rValue": 28,
							"bValue": 102,
							"gValue": 61,
							"aValue": 1
						}
					}]
				}, {
					"actionItems": [{
						"id": "a-5-n-2",
						"actionTypeId": "STYLE_TEXT_COLOR",
						"config": {
							"delay": 0,
							"easing": "easeOut",
							"duration": 300,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".resources-list-link",
								"selectorGuids": ["2c83ce5d-ee26-deca-6ca6-e24f7d5ea30b"]
							},
							"globalSwatchId": "d7f3d0d9",
							"rValue": 170,
							"bValue": 71,
							"gValue": 30,
							"aValue": 1
						}
					}, {
						"id": "a-5-n-4",
						"actionTypeId": "STYLE_BACKGROUND_COLOR",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 300,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".arrow-link-block.resources",
								"selectorGuids": ["6707f1ef-0211-9eca-af1f-a25aee77ef3a", "2222b338-734e-8774-8abe-78f3087c30a0"]
							},
							"globalSwatchId": "d7f3d0d9",
							"rValue": 170,
							"bValue": 71,
							"gValue": 30,
							"aValue": 1
						}
					}]
				}],
				"useFirstGroupAsInitialState": true,
				"createdOn": 1631568932719
			},
			"a-6": {
				"id": "a-6",
				"title": "resource list item hover out",
				"actionItemGroups": [{
					"actionItems": [{
						"id": "a-6-n",
						"actionTypeId": "STYLE_TEXT_COLOR",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 300,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".resources-list-link",
								"selectorGuids": ["2c83ce5d-ee26-deca-6ca6-e24f7d5ea30b"]
							},
							"globalSwatchId": "4f0d3bd6",
							"rValue": 28,
							"bValue": 102,
							"gValue": 61,
							"aValue": 1
						}
					}, {
						"id": "a-6-n-2",
						"actionTypeId": "STYLE_BACKGROUND_COLOR",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 200,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".arrow-link-block.resources",
								"selectorGuids": ["6707f1ef-0211-9eca-af1f-a25aee77ef3a", "2222b338-734e-8774-8abe-78f3087c30a0"]
							},
							"globalSwatchId": "4f0d3bd6",
							"rValue": 28,
							"bValue": 102,
							"gValue": 61,
							"aValue": 1
						}
					}]
				}],
				"useFirstGroupAsInitialState": false,
				"createdOn": 1631568932719
			},
			"a-8": {
				"id": "a-8",
				"title": "open search",
				"actionItemGroups": [{
					"actionItems": [{
						"id": "a-8-n-3",
						"actionTypeId": "STYLE_OPACITY",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 500,
							"target": {
								"selector": ".search-bar-wrap",
								"selectorGuids": ["9be74eec-fc8f-20f4-3e36-b96bade68646"]
							},
							"value": 0,
							"unit": ""
						}
					}, {
						"id": "a-8-n-6",
						"actionTypeId": "STYLE_SIZE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 500,
							"target": {
								"selector": ".search-bar-wrap",
								"selectorGuids": ["9be74eec-fc8f-20f4-3e36-b96bade68646"]
							},
							"heightValue": 0,
							"widthUnit": "PX",
							"heightUnit": "px",
							"locked": false
						}
					}]
				}, {
					"actionItems": [{
						"id": "a-8-n-4",
						"actionTypeId": "STYLE_OPACITY",
						"config": {
							"delay": 0,
							"easing": "inOutQuad",
							"duration": 350,
							"target": {
								"selector": ".search-bar-wrap",
								"selectorGuids": ["9be74eec-fc8f-20f4-3e36-b96bade68646"]
							},
							"value": 1,
							"unit": ""
						}
					}, {
						"id": "a-8-n-7",
						"actionTypeId": "STYLE_SIZE",
						"config": {
							"delay": 0,
							"easing": "inOutQuad",
							"duration": 350,
							"target": {
								"selector": ".search-bar-wrap",
								"selectorGuids": ["9be74eec-fc8f-20f4-3e36-b96bade68646"]
							},
							"widthUnit": "PX",
							"heightUnit": "AUTO",
							"locked": false
						}
					}]
				}],
				"useFirstGroupAsInitialState": true,
				"createdOn": 1631642777169
			},
			"a-9": {
				"id": "a-9",
				"title": "close search",
				"actionItemGroups": [{
					"actionItems": [{
						"id": "a-9-n",
						"actionTypeId": "STYLE_SIZE",
						"config": {
							"delay": 0,
							"easing": "outQuad",
							"duration": 2000,
							"target": {
								"useEventTarget": "PARENT",
								"selector": ".search-bar-wrap",
								"selectorGuids": ["9be74eec-fc8f-20f4-3e36-b96bade68646"]
							},
							"heightValue": 0,
							"widthUnit": "PX",
							"heightUnit": "px",
							"locked": false
						}
					}, {
						"id": "a-9-n-2",
						"actionTypeId": "STYLE_OPACITY",
						"config": {
							"delay": 0,
							"easing": "outQuad",
							"duration": 100,
							"target": {
								"useEventTarget": "PARENT",
								"selector": ".search-bar-wrap",
								"selectorGuids": ["9be74eec-fc8f-20f4-3e36-b96bade68646"]
							},
							"value": 0,
							"unit": ""
						}
					}]
				}],
				"useFirstGroupAsInitialState": false,
				"createdOn": 1631643272209
			},
			"a-12": {
				"id": "a-12",
				"title": "nav scroll up",
				"actionItemGroups": [{
					"actionItems": [{
						"id": "a-12-n",
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 300,
							"target": {
								"selector": ".main-navbar-wrapper",
								"selectorGuids": ["71321204-9c94-9c3e-e509-afaefc5021e3"]
							},
							"yValue": 0,
							"xUnit": "PX",
							"yUnit": "px",
							"zUnit": "PX"
						}
					}, {
						"id": "a-12-n-10",
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 300,
							"target": {
								"selector": ".search-bar-wrap",
								"selectorGuids": ["9be74eec-fc8f-20f4-3e36-b96bade68646"]
							},
							"yValue": 0,
							"xUnit": "PX",
							"yUnit": "px",
							"zUnit": "PX"
						}
					}, {
						"id": "a-12-n-9",
						"actionTypeId": "STYLE_BORDER",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 200,
							"target": {
								"selector": ".online-banking-login-button",
								"selectorGuids": ["9eb0c53e-8b4c-16b8-2a76-a58e3abb7577"]
							},
							"globalSwatchId": "d7f3d0d9",
							"rValue": 170,
							"bValue": 71,
							"gValue": 30,
							"aValue": 1
						}
					}, {
						"id": "a-12-n-2",
						"actionTypeId": "TRANSFORM_SCALE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 200,
							"target": {
								"selector": ".online-banking-login-button",
								"selectorGuids": ["9eb0c53e-8b4c-16b8-2a76-a58e3abb7577"]
							},
							"xValue": 1,
							"yValue": 1,
							"locked": true
						}
					}, {
						"id": "a-12-n-3",
						"actionTypeId": "TRANSFORM_SCALE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 200,
							"target": {
								"selector": ".careers-link",
								"selectorGuids": ["d8a45ee8-846e-c79d-e6fb-725950e75c06"]
							},
							"xValue": 1,
							"yValue": 1,
							"locked": true
						}
					}, {
						"id": "a-12-n-4",
						"actionTypeId": "TRANSFORM_SCALE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 200,
							"target": {
								"selector": ".news-link",
								"selectorGuids": ["d8a45ee8-846e-c79d-e6fb-725950e75c05"]
							},
							"xValue": 1,
							"yValue": 1,
							"locked": true
						}
					}, {
						"id": "a-12-n-5",
						"actionTypeId": "TRANSFORM_SCALE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 200,
							"target": {
								"selector": ".about-link",
								"selectorGuids": ["d8a45ee8-846e-c79d-e6fb-725950e75c03"]
							},
							"xValue": 1,
							"yValue": 1,
							"locked": true
						}
					}, {
						"id": "a-12-n-6",
						"actionTypeId": "STYLE_OPACITY",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 300,
							"target": {
								"selector": ".online-banking-login-button",
								"selectorGuids": ["9eb0c53e-8b4c-16b8-2a76-a58e3abb7577"]
							},
							"value": 1,
							"unit": ""
						}
					}, {
						"id": "a-12-n-7",
						"actionTypeId": "STYLE_BACKGROUND_COLOR",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 200,
							"target": {
								"selector": ".online-banking-login-button",
								"selectorGuids": ["9eb0c53e-8b4c-16b8-2a76-a58e3abb7577"]
							},
							"globalSwatchId": "d7f3d0d9",
							"rValue": 170,
							"bValue": 71,
							"gValue": 30,
							"aValue": 1
						}
					}, {
						"id": "a-12-n-8",
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 300,
							"target": {
								"selector": ".nav-top-header-wrapper",
								"selectorGuids": ["d8a45ee8-846e-c79d-e6fb-725950e75c02"]
							},
							"yValue": 0,
							"xUnit": "PX",
							"yUnit": "px",
							"zUnit": "PX"
						}
					}]
				}],
				"useFirstGroupAsInitialState": false,
				"createdOn": 1599769199640
			},
			"a-11": {
				"id": "a-11",
				"title": "nav scroll down",
				"actionItemGroups": [{
					"actionItems": [{
						"id": "a-11-n",
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 300,
							"target": {
								"selector": ".main-navbar-wrapper",
								"selectorGuids": ["71321204-9c94-9c3e-e509-afaefc5021e3"]
							},
							"yValue": -15,
							"xUnit": "PX",
							"yUnit": "px",
							"zUnit": "PX"
						}
					}, {
						"id": "a-11-n-16",
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 300,
							"target": {
								"selector": ".search-bar-wrap",
								"selectorGuids": ["9be74eec-fc8f-20f4-3e36-b96bade68646"]
							},
							"yValue": -15,
							"xUnit": "PX",
							"yUnit": "px",
							"zUnit": "PX"
						}
					}, {
						"id": "a-11-n-14",
						"actionTypeId": "TRANSFORM_SCALE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 300,
							"target": {
								"selector": ".online-banking-login-button",
								"selectorGuids": ["9eb0c53e-8b4c-16b8-2a76-a58e3abb7577"]
							},
							"xValue": 0.88,
							"yValue": 0.88,
							"locked": true
						}
					}, {
						"id": "a-11-n-13",
						"actionTypeId": "TRANSFORM_SCALE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 300,
							"target": {
								"selector": ".careers-link",
								"selectorGuids": ["d8a45ee8-846e-c79d-e6fb-725950e75c06"]
							},
							"xValue": 0.9,
							"yValue": 0.9,
							"locked": true
						}
					}, {
						"id": "a-11-n-12",
						"actionTypeId": "TRANSFORM_SCALE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 300,
							"target": {
								"selector": ".news-link",
								"selectorGuids": ["d8a45ee8-846e-c79d-e6fb-725950e75c05"]
							},
							"xValue": 0.9,
							"yValue": 0.9,
							"locked": true
						}
					}, {
						"id": "a-11-n-11",
						"actionTypeId": "TRANSFORM_SCALE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 300,
							"target": {
								"selector": ".about-link",
								"selectorGuids": ["d8a45ee8-846e-c79d-e6fb-725950e75c03"]
							},
							"xValue": 0.9,
							"yValue": 0.9,
							"locked": true
						}
					}, {
						"id": "a-11-n-10",
						"actionTypeId": "STYLE_OPACITY",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 200,
							"target": {
								"selector": ".online-banking-login-button",
								"selectorGuids": ["9eb0c53e-8b4c-16b8-2a76-a58e3abb7577"]
							},
							"value": 1,
							"unit": ""
						}
					}, {
						"id": "a-11-n-7",
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 300,
							"target": {
								"selector": ".nav-top-header-wrapper",
								"selectorGuids": ["d8a45ee8-846e-c79d-e6fb-725950e75c02"]
							},
							"yValue": -7,
							"xUnit": "PX",
							"yUnit": "px",
							"zUnit": "PX"
						}
					}]
				}],
				"useFirstGroupAsInitialState": false,
				"createdOn": 1599769199640
			},
			"a-16": {
				"id": "a-16",
				"title": "resources scale up",
				"actionItemGroups": [{
					"actionItems": [{
						"id": "a-16-n",
						"actionTypeId": "TRANSFORM_SCALE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 500,
							"target": {
								"useEventTarget": true,
								"id": "614b9b789937c3ff3ca2756b|52d77029-49d9-b431-3915-5d839125348a"
							},
							"xValue": 1,
							"yValue": 1,
							"locked": true
						}
					}]
				}, {
					"actionItems": [{
						"id": "a-16-n-2",
						"actionTypeId": "TRANSFORM_SCALE",
						"config": {
							"delay": 0,
							"easing": "inOutQuad",
							"duration": 300,
							"target": {
								"useEventTarget": true,
								"id": "614b9b789937c3ff3ca2756b|52d77029-49d9-b431-3915-5d839125348a"
							},
							"xValue": 1.04,
							"yValue": 1.04,
							"locked": true
						}
					}]
				}],
				"useFirstGroupAsInitialState": true,
				"createdOn": 1631821005260
			},
			"a-17": {
				"id": "a-17",
				"title": "resources scale down",
				"actionItemGroups": [{
					"actionItems": [{
						"id": "a-17-n",
						"actionTypeId": "TRANSFORM_SCALE",
						"config": {
							"delay": 0,
							"easing": "inOutQuad",
							"duration": 200,
							"target": {
								"useEventTarget": true,
								"id": "614b9b789937c3ff3ca2756b|52d77029-49d9-b431-3915-5d839125348a"
							},
							"xValue": 1,
							"yValue": 1,
							"locked": true
						}
					}]
				}],
				"useFirstGroupAsInitialState": false,
				"createdOn": 1631821005260
			},
			"a-14": {
				"id": "a-14",
				"title": "online banking button hover",
				"actionItemGroups": [{
					"actionItems": [{
						"id": "a-14-n",
						"actionTypeId": "STYLE_BACKGROUND_COLOR",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 500,
							"target": {
								"useEventTarget": true,
								"id": "614b9b789937c3ff3ca2756b|c339b250-cae2-b901-4891-96b3388eb5a0"
							},
							"globalSwatchId": "d7f3d0d9",
							"rValue": 170,
							"bValue": 71,
							"gValue": 30,
							"aValue": 1
						}
					}]
				}, {
					"actionItems": [{
						"id": "a-14-n-2",
						"actionTypeId": "STYLE_BACKGROUND_COLOR",
						"config": {
							"delay": 0,
							"easing": "outQuad",
							"duration": 200,
							"target": {
								"useEventTarget": true,
								"id": "614b9b789937c3ff3ca2756b|c339b250-cae2-b901-4891-96b3388eb5a0"
							},
							"globalSwatchId": "56b8505a",
							"rValue": 187,
							"bValue": 92,
							"gValue": 53,
							"aValue": 1
						}
					}]
				}],
				"useFirstGroupAsInitialState": true,
				"createdOn": 1631811169895
			},
			"a-15": {
				"id": "a-15",
				"title": "online banking button hover out",
				"actionItemGroups": [{
					"actionItems": [{
						"id": "a-15-n",
						"actionTypeId": "STYLE_BACKGROUND_COLOR",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 200,
							"target": {
								"useEventTarget": true,
								"id": "614b9b789937c3ff3ca2756b|c339b250-cae2-b901-4891-96b3388eb5a0"
							},
							"globalSwatchId": "d7f3d0d9",
							"rValue": 170,
							"bValue": 71,
							"gValue": 30,
							"aValue": 1
						}
					}]
				}],
				"useFirstGroupAsInitialState": false,
				"createdOn": 1631811169895
			},
			"a-18": {
				"id": "a-18",
				"title": "CB scale up",
				"actionItemGroups": [{
					"actionItems": [{
						"id": "a-18-n",
						"actionTypeId": "TRANSFORM_SCALE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 500,
							"target": {
								"useEventTarget": true,
								"id": "614b9b789937c3ff3ca2756b|52d77029-49d9-b431-3915-5d839125348a"
							},
							"xValue": 1,
							"yValue": 1,
							"locked": true
						}
					}, {
						"id": "a-18-n-4",
						"actionTypeId": "STYLE_TEXT_COLOR",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 500,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".h4-heading",
								"selectorGuids": ["96e32ffb-2669-de05-16f7-2187319784c1"]
							},
							"globalSwatchId": "4f0d3bd6",
							"rValue": 28,
							"bValue": 102,
							"gValue": 61,
							"aValue": 1
						}
					}]
				}, {
					"actionItems": [{
						"id": "a-18-n-2",
						"actionTypeId": "TRANSFORM_SCALE",
						"config": {
							"delay": 0,
							"easing": "inOutQuad",
							"duration": 300,
							"target": {
								"useEventTarget": true,
								"id": "614b9b789937c3ff3ca2756b|52d77029-49d9-b431-3915-5d839125348a"
							},
							"xValue": 1.04,
							"yValue": 1.04,
							"locked": true
						}
					}, {
						"id": "a-18-n-3",
						"actionTypeId": "STYLE_TEXT_COLOR",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 200,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".h4-heading",
								"selectorGuids": ["96e32ffb-2669-de05-16f7-2187319784c1"]
							},
							"globalSwatchId": "d7f3d0d9",
							"rValue": 170,
							"bValue": 71,
							"gValue": 30,
							"aValue": 1
						}
					}]
				}],
				"useFirstGroupAsInitialState": true,
				"createdOn": 1631821005260
			},
			"a-19": {
				"id": "a-19",
				"title": "CB scale down",
				"actionItemGroups": [{
					"actionItems": [{
						"id": "a-19-n",
						"actionTypeId": "TRANSFORM_SCALE",
						"config": {
							"delay": 0,
							"easing": "inOutQuad",
							"duration": 200,
							"target": {
								"useEventTarget": true,
								"id": "614b9b789937c3ff3ca2756b|52d77029-49d9-b431-3915-5d839125348a"
							},
							"xValue": 1,
							"yValue": 1,
							"locked": true
						}
					}, {
						"id": "a-19-n-2",
						"actionTypeId": "STYLE_TEXT_COLOR",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 100,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".h4-heading",
								"selectorGuids": ["96e32ffb-2669-de05-16f7-2187319784c1"]
							},
							"globalSwatchId": "4f0d3bd6",
							"rValue": 28,
							"bValue": 102,
							"gValue": 61,
							"aValue": 1
						}
					}]
				}],
				"useFirstGroupAsInitialState": false,
				"createdOn": 1631821005260
			},
			"slideInBottom": {
				"id": "slideInBottom",
				"useFirstGroupAsInitialState": true,
				"actionItemGroups": [{
					"actionItems": [{
						"actionTypeId": "STYLE_OPACITY",
						"config": {
							"delay": 0,
							"duration": 0,
							"target": {
								"id": "N/A",
								"appliesTo": "TRIGGER_ELEMENT",
								"useEventTarget": true
							},
							"value": 0
						}
					}]
				}, {
					"actionItems": [{
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"duration": 0,
							"target": {
								"id": "N/A",
								"appliesTo": "TRIGGER_ELEMENT",
								"useEventTarget": true
							},
							"xValue": 0,
							"yValue": 100,
							"xUnit": "PX",
							"yUnit": "PX",
							"zUnit": "PX"
						}
					}]
				}, {
					"actionItems": [{
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"easing": "outQuart",
							"duration": 1000,
							"target": {
								"id": "N/A",
								"appliesTo": "TRIGGER_ELEMENT",
								"useEventTarget": true
							},
							"xValue": 0,
							"yValue": 0,
							"xUnit": "PX",
							"yUnit": "PX",
							"zUnit": "PX"
						}
					}, {
						"actionTypeId": "STYLE_OPACITY",
						"config": {
							"delay": 0,
							"easing": "outQuart",
							"duration": 1000,
							"target": {
								"id": "N/A",
								"appliesTo": "TRIGGER_ELEMENT",
								"useEventTarget": true
							},
							"value": 1
						}
					}]
				}]
			},
			"growIn": {
				"id": "growIn",
				"useFirstGroupAsInitialState": true,
				"actionItemGroups": [{
					"actionItems": [{
						"actionTypeId": "STYLE_OPACITY",
						"config": {
							"delay": 0,
							"duration": 0,
							"target": {
								"id": "N/A",
								"appliesTo": "TRIGGER_ELEMENT",
								"useEventTarget": true
							},
							"value": 0
						}
					}]
				}, {
					"actionItems": [{
						"actionTypeId": "TRANSFORM_SCALE",
						"config": {
							"delay": 0,
							"duration": 0,
							"target": {
								"id": "N/A",
								"appliesTo": "TRIGGER_ELEMENT",
								"useEventTarget": true
							},
							"xValue": 0.7500000000000001,
							"yValue": 0.7500000000000001
						}
					}]
				}, {
					"actionItems": [{
						"actionTypeId": "TRANSFORM_SCALE",
						"config": {
							"delay": 0,
							"easing": "outQuart",
							"duration": 1000,
							"target": {
								"id": "N/A",
								"appliesTo": "TRIGGER_ELEMENT",
								"useEventTarget": true
							},
							"xValue": 1,
							"yValue": 1
						}
					}, {
						"actionTypeId": "STYLE_OPACITY",
						"config": {
							"delay": 0,
							"easing": "outQuart",
							"duration": 1000,
							"target": {
								"id": "N/A",
								"appliesTo": "TRIGGER_ELEMENT",
								"useEventTarget": true
							},
							"value": 1
						}
					}]
				}]
			},
			"slideInLeft": {
				"id": "slideInLeft",
				"useFirstGroupAsInitialState": true,
				"actionItemGroups": [{
					"actionItems": [{
						"actionTypeId": "STYLE_OPACITY",
						"config": {
							"delay": 0,
							"duration": 0,
							"target": {
								"id": "N/A",
								"appliesTo": "TRIGGER_ELEMENT",
								"useEventTarget": true
							},
							"value": 0
						}
					}]
				}, {
					"actionItems": [{
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"duration": 0,
							"target": {
								"id": "N/A",
								"appliesTo": "TRIGGER_ELEMENT",
								"useEventTarget": true
							},
							"xValue": -100,
							"yValue": 0,
							"xUnit": "PX",
							"yUnit": "PX",
							"zUnit": "PX"
						}
					}]
				}, {
					"actionItems": [{
						"actionTypeId": "STYLE_OPACITY",
						"config": {
							"delay": 0,
							"easing": "outQuart",
							"duration": 1000,
							"target": {
								"id": "N/A",
								"appliesTo": "TRIGGER_ELEMENT",
								"useEventTarget": true
							},
							"value": 1
						}
					}, {
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"easing": "outQuart",
							"duration": 1000,
							"target": {
								"id": "N/A",
								"appliesTo": "TRIGGER_ELEMENT",
								"useEventTarget": true
							},
							"xValue": 0,
							"yValue": 0,
							"xUnit": "PX",
							"yUnit": "PX",
							"zUnit": "PX"
						}
					}]
				}]
			},
			"slideInRight": {
				"id": "slideInRight",
				"useFirstGroupAsInitialState": true,
				"actionItemGroups": [{
					"actionItems": [{
						"actionTypeId": "STYLE_OPACITY",
						"config": {
							"delay": 0,
							"duration": 0,
							"target": {
								"id": "N/A",
								"appliesTo": "TRIGGER_ELEMENT",
								"useEventTarget": true
							},
							"value": 0
						}
					}]
				}, {
					"actionItems": [{
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"duration": 0,
							"target": {
								"id": "N/A",
								"appliesTo": "TRIGGER_ELEMENT",
								"useEventTarget": true
							},
							"xValue": 100,
							"yValue": 0,
							"xUnit": "PX",
							"yUnit": "PX",
							"zUnit": "PX"
						}
					}]
				}, {
					"actionItems": [{
						"actionTypeId": "STYLE_OPACITY",
						"config": {
							"delay": 0,
							"easing": "outQuart",
							"duration": 1000,
							"target": {
								"id": "N/A",
								"appliesTo": "TRIGGER_ELEMENT",
								"useEventTarget": true
							},
							"value": 1
						}
					}, {
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"easing": "outQuart",
							"duration": 1000,
							"target": {
								"id": "N/A",
								"appliesTo": "TRIGGER_ELEMENT",
								"useEventTarget": true
							},
							"xValue": 0,
							"yValue": 0,
							"xUnit": "PX",
							"yUnit": "PX",
							"zUnit": "PX"
						}
					}]
				}]
			}
		},
		"site": {
			"mediaQueries": [{
				"key": "main",
				"min": 992,
				"max": 10000
			}, {
				"key": "medium",
				"min": 768,
				"max": 991
			}, {
				"key": "small",
				"min": 480,
				"max": 767
			}, {
				"key": "tiny",
				"min": 0,
				"max": 479
			}]
		}
	});
	/* eslint-enable */
})

function addStickyHeader() {
	if ($(window).scrollTop() > 1) {
		$('.navbar').addClass('sticky');
	}
	else {
		$('.navbar').removeClass('sticky');
	}
}
